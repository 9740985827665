// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as CoursesCurriculum__User from "./CoursesCurriculum__User.bs.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";
import * as CoursesCurriculum__Reaction from "./CoursesCurriculum__Reaction.bs.js";
import * as CoursesCurriculum__ModerationReport from "./CoursesCurriculum__ModerationReport.bs.js";
import * as CoursesCurriculum__SubmissionComment from "./CoursesCurriculum__SubmissionComment.bs.js";

var UnexpectedStatusValue = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__DiscussionSubmission.UnexpectedStatusValue");

function id(t) {
  return t.id;
}

function targetId(t) {
  return t.targetId;
}

function createdAt(t) {
  return t.createdAt;
}

function checklist(t) {
  return t.checklist;
}

function userNames(t) {
  return t.userNames;
}

function users(t) {
  return t.users;
}

function teamName(t) {
  return t.teamName;
}

function comments(t) {
  return t.comments;
}

function reactions(t) {
  return t.reactions;
}

function anonymous(t) {
  return t.anonymous;
}

function pinned(t) {
  return t.pinned;
}

function moderationReports(t) {
  return t.moderationReports;
}

function hiddenAt(t) {
  return t.hiddenAt;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function hiddenAtPretty(t) {
  var hiddenAt = t.hiddenAt;
  if (hiddenAt !== undefined) {
    return DateFns.format(Caml_option.valFromOption(hiddenAt), "MMMM d, yyyy");
  } else {
    return "";
  }
}

function sort(ts) {
  return ArrayUtils.copyAndSort((function (t1, t2) {
                return DateFns.differenceInSeconds(t2.createdAt, t1.createdAt);
              }), ts);
}

function firstUser(t) {
  return Caml_array.get(t.users, 0);
}

function decode(json) {
  var partial_arg = Json_decode.field("files", (function (param) {
          return Json_decode.array(SubmissionChecklistItem.decodeFile, param);
        }), json);
  var partial_arg$1 = function (param) {
    return SubmissionChecklistItem.decode(partial_arg, param);
  };
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          targetId: Json_decode.field("targetId", Json_decode.string, json),
          createdAt: Json_decode.field("createdAt", DateFns.decodeISO, json),
          checklist: Json_decode.field("checklist", (function (param) {
                  return Json_decode.array(partial_arg$1, param);
                }), json),
          userNames: Json_decode.field("userNames", Json_decode.string, json),
          users: Json_decode.field("users", (function (param) {
                  return Json_decode.array(CoursesCurriculum__User.decode, param);
                }), json),
          teamName: Json_decode.optional((function (param) {
                  return Json_decode.field("teamName", Json_decode.string, param);
                }), json),
          comments: Json_decode.field("comments", (function (param) {
                  return Json_decode.array(CoursesCurriculum__SubmissionComment.decode, param);
                }), json),
          reactions: Json_decode.field("reactions", (function (param) {
                  return Json_decode.array(CoursesCurriculum__Reaction.decode, param);
                }), json),
          anonymous: Json_decode.field("anonymous", Json_decode.bool, json),
          pinned: Json_decode.field("pinned", Json_decode.bool, json),
          moderationReports: Json_decode.field("moderationReports", (function (param) {
                  return Json_decode.array(CoursesCurriculum__ModerationReport.decode, param);
                }), json),
          hiddenAt: Json_decode.optional((function (param) {
                  return Json_decode.field("hiddenAt", DateFns.decodeISO, param);
                }), json)
        };
}

function make(id, targetId, createdAt, checklist, userNames, users, teamName, comments, reactions, anonymous, pinned, moderationReports, hiddenAt) {
  return {
          id: id,
          targetId: targetId,
          createdAt: createdAt,
          checklist: checklist,
          userNames: userNames,
          users: users,
          teamName: teamName,
          comments: comments,
          reactions: reactions,
          anonymous: anonymous,
          pinned: pinned,
          moderationReports: moderationReports,
          hiddenAt: hiddenAt
        };
}

export {
  UnexpectedStatusValue ,
  id ,
  targetId ,
  createdAt ,
  checklist ,
  userNames ,
  users ,
  teamName ,
  comments ,
  reactions ,
  anonymous ,
  pinned ,
  moderationReports ,
  hiddenAt ,
  createdAtPretty ,
  hiddenAtPretty ,
  sort ,
  firstUser ,
  decode ,
  make ,
}
/* DateFns Not a pure module */
