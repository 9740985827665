// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function userId(t) {
  return t.userId;
}

function reportableId(t) {
  return t.reportableId;
}

function reportableType(t) {
  return t.reportableType;
}

function reason(t) {
  return t.reason;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          userId: Json_decode.field("userId", Json_decode.string, json),
          reportableId: Json_decode.field("reportableId", Json_decode.string, json),
          reportableType: Json_decode.field("reportableType", Json_decode.string, json),
          reason: Json_decode.field("reason", Json_decode.string, json)
        };
}

export {
  id ,
  userId ,
  reportableId ,
  reportableType ,
  reason ,
  decode ,
}
/* No side effect */
