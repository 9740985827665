// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";

var UnexpectedStatusValue = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Submission.UnexpectedStatusValue");

function id(t) {
  return t.id;
}

function createdAt(t) {
  return t.createdAt;
}

function status(t) {
  return t.status;
}

function checklist(t) {
  return t.checklist;
}

function hiddenAt(t) {
  return t.hiddenAt;
}

function pending(t) {
  var match = t.status;
  return match === 1;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function hiddenAtPretty(t) {
  var hiddenAt = t.hiddenAt;
  if (hiddenAt !== undefined) {
    return DateFns.format(Caml_option.valFromOption(hiddenAt), "MMMM d, yyyy");
  } else {
    return "";
  }
}

function sort(ts) {
  return ArrayUtils.copyAndSort((function (t1, t2) {
                return DateFns.differenceInSeconds(t2.createdAt, t1.createdAt);
              }), ts);
}

function decode(json) {
  var unknownValue = Json_decode.field("status", Json_decode.string, json);
  var tmp;
  switch (unknownValue) {
    case "failed" :
        tmp = /* Rejected */3;
        break;
    case "marked_as_complete" :
        tmp = /* MarkedAsComplete */0;
        break;
    case "passed" :
        tmp = /* Completed */2;
        break;
    case "pending" :
        tmp = /* Pending */1;
        break;
    default:
      throw {
            RE_EXN_ID: UnexpectedStatusValue,
            _1: unknownValue,
            Error: new Error()
          };
  }
  var partial_arg = Json_decode.field("files", (function (param) {
          return Json_decode.array(SubmissionChecklistItem.decodeFile, param);
        }), json);
  var partial_arg$1 = function (param) {
    return SubmissionChecklistItem.decode(partial_arg, param);
  };
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          createdAt: Json_decode.field("createdAt", DateFns.decodeISO, json),
          status: tmp,
          checklist: Json_decode.field("checklist", (function (param) {
                  return Json_decode.array(partial_arg$1, param);
                }), json),
          hiddenAt: Json_decode.optional((function (param) {
                  return Json_decode.field("hiddenAt", DateFns.decodeISO, param);
                }), json)
        };
}

function make(id, createdAt, status, checklist, hiddenAt) {
  return {
          id: id,
          createdAt: createdAt,
          status: status,
          checklist: checklist,
          hiddenAt: hiddenAt
        };
}

export {
  UnexpectedStatusValue ,
  id ,
  createdAt ,
  status ,
  checklist ,
  hiddenAt ,
  pending ,
  createdAtPretty ,
  hiddenAtPretty ,
  sort ,
  decode ,
  make ,
}
/* DateFns Not a pure module */
