// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as DateFns from "../shared/utils/DateFns.bs.js";
import * as DOMPurify from "../shared/utils/DOMPurify.bs.js";
import * as ReactUtils from "../shared/utils/ReactUtils.bs.js";
import * as IssuedCertificate from "../shared/types/IssuedCertificate.bs.js";
import * as IssuedCertificate__Root from "./IssuedCertificate__Root.bs.js";
import GraduateIconSvg from "./images/graduate-icon.svg";

var graduateIcon = GraduateIconSvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.VerifyCertificate__Root";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function printCertificate(setViewMode, _event) {
  Curry._1(setViewMode, (function (param) {
          return /* Print */1;
        }));
  setTimeout((function (param) {
          window.print();
        }), 1000);
}

function heading(currentUser, issuedCertificate) {
  if (currentUser) {
    return React.createElement("span", {
                dangerouslySetInnerHTML: DOMPurify.sanitizedHTMLOpt(t([[
                            "name",
                            IssuedCertificate.profileName(issuedCertificate)
                          ]], undefined, "heading"), DOMPurify.makeOptions(undefined, [
                          "strong",
                          "br"
                        ], undefined))
              });
  } else {
    return IssuedCertificate.serialNumber(issuedCertificate);
  }
}

function handleCancelPrint(setViewMode, _event) {
  return Curry._1(setViewMode, (function (param) {
                return /* Screen */0;
              }));
}

function issuedToName(issuedCertificate) {
  var issuedTo = IssuedCertificate.issuedTo(issuedCertificate);
  var profileName = IssuedCertificate.profileName(issuedCertificate);
  if (issuedTo === profileName) {
    return profileName;
  } else {
    return "<a href=\"#name-change-notice\" className=\"text-blue-500 hover:text-blue-600\">" + profileName + "<i className=\"ms-1 fas fa-exclamation-circle\" /></a>";
  }
}

function VerifyCertificate__Root(Props) {
  var issuedCertificate = Props.issuedCertificate;
  var verifyImageUrl = Props.verifyImageUrl;
  var currentUser = Props.currentUser;
  var match = React.useState(function () {
        return /* Screen */0;
      });
  var setViewMode = match[1];
  if (match[0]) {
    return React.createElement("div", {
                className: "flex flex-col items-start"
              }, React.createElement("div", {
                    className: "print:hidden pb-4 md:pt-20"
                  }, React.createElement("button", {
                        className: "btn btn-subtle verify-certificate__cancel-button",
                        onClick: (function (param) {
                            return Curry._1(setViewMode, (function (param) {
                                          return /* Screen */0;
                                        }));
                          })
                      }, React.createElement("i", {
                            className: "fas fa-undo-alt"
                          }), React.createElement("span", {
                            className: "ms-1"
                          }, t(undefined, undefined, "cancel")))), React.createElement(IssuedCertificate__Root.make, {
                    issuedCertificate: issuedCertificate,
                    verifyImageUrl: verifyImageUrl
                  }));
  } else {
    return React.createElement("div", {
                className: "container mt-16 mx-auto px-3 max-w-5xl py-8"
              }, React.createElement("div", {
                    className: "border border-gray-300 rounded-lg bg-gray-50 p-3 md:p-6 flex flex-col md:flex-row items-start md:items-center"
                  }, React.createElement("div", {
                        className: "text-center md:w-5/12 pe-0  md:pe-5"
                      }, React.createElement("img", {
                            className: "w-18 md:w-24 mx-auto",
                            src: graduateIcon
                          }), React.createElement("h3", {
                            className: "font-semibold mt-1 md:mt-2"
                          }, heading(currentUser, issuedCertificate)), React.createElement("div", {
                            className: "text-sm mt-4",
                            dangerouslySetInnerHTML: DOMPurify.sanitizedHTMLOpt(t([
                                      [
                                        "name",
                                        issuedToName(issuedCertificate)
                                      ],
                                      [
                                        "issue_date",
                                        DateFns.formatPreset(IssuedCertificate.issuedAt(issuedCertificate), true, true, undefined, undefined)
                                      ],
                                      [
                                        "course_name",
                                        IssuedCertificate.courseName(issuedCertificate)
                                      ]
                                    ], undefined, "description"), DOMPurify.makeOptions(undefined, ["strong"], undefined))
                          }), ReactUtils.nullUnless(React.createElement("div", {
                                className: "mt-4 text-xs"
                              }, React.createElement("code", undefined, t([[
                                          "serial",
                                          IssuedCertificate.serialNumber(issuedCertificate)
                                        ]], undefined, "serial_number"))), currentUser), React.createElement("div", {
                            className: "mt-4"
                          }, React.createElement("button", {
                                className: "btn btn-primary",
                                onClick: (function (param) {
                                    return printCertificate(setViewMode, param);
                                  })
                              }, React.createElement("i", {
                                    className: "fas fa-print"
                                  }), React.createElement("span", {
                                    className: "ms-2"
                                  }, t(undefined, undefined, "print_or_save"))))), React.createElement("div", {
                        className: "md:w-7/12 mt-6 md:mt-0 rounded-lg overflow-hidden border-8 border-white shadow-lg"
                      }, React.createElement(IssuedCertificate__Root.make, {
                            issuedCertificate: issuedCertificate,
                            verifyImageUrl: verifyImageUrl
                          }))), ReactUtils.nullIf(React.createElement("div", {
                        className: "border border-blue-200 rounded-lg shadow-lg bg-blue-100 p-3 md:p-6 mt-6 flex items-center",
                        id: "name-change-notice"
                      }, React.createElement("div", undefined, React.createElement("i", {
                                className: "fas fa-exclamation-circle text-2xl text-blue-500"
                              })), React.createElement("div", {
                            className: "ms-4 text-sm",
                            dangerouslySetInnerHTML: DOMPurify.sanitizedHTMLOpt(t([[
                                        "name",
                                        IssuedCertificate.issuedTo(issuedCertificate)
                                      ]], undefined, "originally_issued_to"), DOMPurify.makeOptions(undefined, ["strong"], undefined))
                          })), IssuedCertificate.profileName(issuedCertificate) === IssuedCertificate.issuedTo(issuedCertificate)));
  }
}

var make = VerifyCertificate__Root;

export {
  graduateIcon ,
  str ,
  t ,
  printCertificate ,
  heading ,
  handleCancelPrint ,
  issuedToName ,
  make ,
}
/* graduateIcon Not a pure module */
