// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as UserEdit from "../users/UserEdit.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("name", Json_decode.string, json),
          Json_decode.field("preferredName", Json_decode.string, json),
          Json_decode.field("about", Json_decode.string, json),
          Json_decode.field("email", Json_decode.string, json),
          Json_decode.field("locale", Json_decode.string, json),
          Json_decode.field("availableLocales", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          Json_decode.field("avatarUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          Json_decode.field("dailyDigest", (function (param) {
                  return Json_decode.optional(Json_decode.bool, param);
                }), json),
          Json_decode.field("hasCurrentPassword", Json_decode.bool, json),
          Json_decode.field("isSchoolAdmin", Json_decode.bool, json),
          Json_decode.field("hasValidDeleteAccountToken", Json_decode.bool, json),
          Json_decode.field("schoolName", Json_decode.string, json)
        ];
}

Psj.match(undefined, "users#edit", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag("user-edit__props", undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(UserEdit.make, {
                    name: match[0],
                    preferredName: match[1],
                    hasCurrentPassword: match[8],
                    about: match[2],
                    locale: match[4],
                    availableLocales: match[5],
                    avatarUrl: match[6],
                    dailyDigest: match[7],
                    isSchoolAdmin: match[9],
                    hasValidDeleteAccountToken: match[10],
                    email: match[3],
                    schoolName: match[11]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
}
/*  Not a pure module */
