// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as CoursesCurriculum__Community from "../types/CoursesCurriculum__Community.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__Discuss";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function linkToCommunity(communityId, targetId) {
  return "/communities/" + (communityId + ("?target_id=" + targetId));
}

function linkToNewPost(communityId, targetId) {
  return "/communities/" + (communityId + ("/new_topic?target_id=" + targetId));
}

function topicCard(topic) {
  var topicId = CoursesCurriculum__Community.topicId(topic);
  var topicLink = "/topics/" + topicId;
  return React.createElement("div", {
              key: topicId,
              className: "flex justify-between items-center px-5 py-4 bg-white border-t",
              href: topicLink
            }, React.createElement("span", {
                  className: "text-sm font-semibold"
                }, CoursesCurriculum__Community.topicTitle(topic)), React.createElement("a", {
                  className: "btn btn-primary-ghost btn-small",
                  href: topicLink
                }, "View"));
}

function handleEmpty(param) {
  return React.createElement("div", {
              className: "flex flex-col justify-center items-center bg-white px-3 py-10"
            }, React.createElement("i", {
                  className: "fa fa-comments text-5xl text-gray-600 mb-2 "
                }), React.createElement("div", {
                  className: "text-center"
                }, React.createElement("h4", {
                      className: "font-bold"
                    }, tr(undefined, undefined, "no_discussion")), React.createElement("p", undefined, tr(undefined, undefined, "use_community"))));
}

function actionButtons(community, targetId) {
  var communityId = CoursesCurriculum__Community.id(community);
  var communityName = CoursesCurriculum__Community.name(community);
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("a", {
                  className: "btn btn-default me-3",
                  title: "Browse all topics about this target in the " + (communityName + " community"),
                  href: linkToCommunity(communityId, targetId)
                }, tr(undefined, undefined, "go_to")), React.createElement("a", {
                  className: "btn btn-primary",
                  title: "Create a topic in the " + (communityName + " community"),
                  href: linkToNewPost(communityId, targetId)
                }, tr(undefined, undefined, "create")));
}

function communityTitle(community) {
  return React.createElement("h5", {
              className: "font-bold"
            }, tr(undefined, undefined, "topics_pre") + (CoursesCurriculum__Community.name(community) + tr(undefined, undefined, "topics_post")));
}

function CoursesCurriculum__Discuss(Props) {
  var targetId = Props.targetId;
  var communities = Props.communities;
  return React.createElement("div", undefined, Js_array.map((function (community) {
                    var communityId = CoursesCurriculum__Community.id(community);
                    var topics = CoursesCurriculum__Community.topics(community);
                    return React.createElement("div", {
                                key: communityId,
                                className: "mt-12 bg-gray-50 px-6 py-4 rounded-lg"
                              }, React.createElement("div", {
                                    className: "flex flex-col md:flex-row w-full justify-between pb-3 items-center"
                                  }, React.createElement("div", undefined, communityTitle(community)), actionButtons(community, targetId)), React.createElement("div", {
                                    className: "justify-between rounded-lg overflow-hidden shadow"
                                  }, topics.length !== 0 ? $$Array.map(topicCard, topics) : handleEmpty(undefined)));
                  }), communities));
}

var make = CoursesCurriculum__Discuss;

export {
  str ,
  tr ,
  linkToCommunity ,
  linkToNewPost ,
  topicCard ,
  handleEmpty ,
  actionButtons ,
  communityTitle ,
  make ,
}
/* I18n Not a pure module */
