// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__ModerationReport from "../types/CoursesCurriculum__ModerationReport.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__ModerationReportButton";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreateModerationReportMutation($reason: String!, $reportableId: String!, $reportableType: String!)  {\ncreateModerationReport(reason: $reason, reportableId: $reportableId, reportableType: $reportableType)  {\nmoderationReport  {\nid  \nreason  \nreportableId  \nreportableType  \nuserId  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createModerationReport;
  var value$2 = value$1.moderationReport;
  var value$3 = value$2.reportableId;
  return {
          createModerationReport: {
            moderationReport: {
              id: value$2.id,
              reason: value$2.reason,
              reportableId: !(value$3 == null) ? value$3 : undefined,
              reportableType: value$2.reportableType,
              userId: value$2.userId
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.createModerationReport;
  var value$2 = value$1.moderationReport;
  var value$3 = value$2.userId;
  var value$4 = value$2.reportableType;
  var value$5 = value$2.reportableId;
  var reportableId = value$5 !== undefined ? value$5 : null;
  var value$6 = value$2.reason;
  var value$7 = value$2.id;
  var moderationReport = {
    id: value$7,
    reason: value$6,
    reportableId: reportableId,
    reportableType: value$4,
    userId: value$3
  };
  var createModerationReport = {
    moderationReport: moderationReport
  };
  return {
          createModerationReport: createModerationReport
        };
}

function serializeVariables(inp) {
  return {
          reason: inp.reason,
          reportableId: inp.reportableId,
          reportableType: inp.reportableType
        };
}

function makeVariables(reason, reportableId, reportableType, param) {
  return {
          reason: reason,
          reportableId: reportableId,
          reportableType: reportableType
        };
}

var CreateModerationReportMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateModerationReportMutation_Graphql_error = include.Graphql_error;

var CreateModerationReportMutation_decodeNotification = include.decodeNotification;

var CreateModerationReportMutation_decodeObject = include.decodeObject;

var CreateModerationReportMutation_decodeNotifications = include.decodeNotifications;

var CreateModerationReportMutation_decodeErrors = include.decodeErrors;

var CreateModerationReportMutation_flashNotifications = include.flashNotifications;

var CreateModerationReportMutation_sendQuery = include.sendQuery;

var CreateModerationReportMutation_query = include.query;

var CreateModerationReportMutation_fetch = include.$$fetch;

var CreateModerationReportMutation = {
  CreateModerationReportMutation_inner: CreateModerationReportMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateModerationReportMutation_Graphql_error,
  decodeNotification: CreateModerationReportMutation_decodeNotification,
  decodeObject: CreateModerationReportMutation_decodeObject,
  decodeNotifications: CreateModerationReportMutation_decodeNotifications,
  decodeErrors: CreateModerationReportMutation_decodeErrors,
  flashNotifications: CreateModerationReportMutation_flashNotifications,
  sendQuery: CreateModerationReportMutation_sendQuery,
  query: CreateModerationReportMutation_query,
  $$fetch: CreateModerationReportMutation_fetch,
  make: make
};

function createModerationReport(reportableType, reportableId, reason, setModerationReports, setShowReport, setReportReason, $$event) {
  $$event.preventDefault();
  Js_promise.then_((function (response) {
          var moderationReport = response.createModerationReport.moderationReport;
          if (moderationReport !== undefined) {
            var moderationReport$1 = Caml_option.valFromOption(moderationReport);
            Curry._1(setModerationReports, (function (moderationReports) {
                    return [moderationReport$1].concat(moderationReports);
                  }));
            Curry._1(setShowReport, (function (param) {
                    return false;
                  }));
            Curry._1(setReportReason, (function (param) {
                    return "";
                  }));
          }
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            reason: reason,
            reportableId: reportableId,
            reportableType: reportableType
          }));
}

function updateReportReason(setReportReason, $$event) {
  $$event.preventDefault();
  return Curry._1(setReportReason, $$event.currentTarget.value);
}

function updateShowReport(setShowReport, showReport, $$event) {
  $$event.preventDefault();
  return Curry._1(setShowReport, (function (param) {
                return showReport;
              }));
}

function CoursesCurriculum__ModerationReportButton(Props) {
  var currentUser = Props.currentUser;
  var moderationReports = Props.moderationReports;
  var reportableId = Props.reportableId;
  var reportableType = Props.reportableType;
  var match = React.useState(function () {
        return moderationReports;
      });
  var setModerationReports = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowReport = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var setReportReason = match$2[1];
  var reportReason = match$2[0];
  var reported = Belt_Array.reduce(match[0], false, (function (acc, report) {
          if (acc) {
            return true;
          } else {
            return CoursesCurriculum__ModerationReport.userId(report) === CoursesCurriculum__CurrentUser.id(currentUser);
          }
        }));
  return React.createElement("div", {
              className: "relative"
            }, match$1[0] ? React.createElement("dialog", {
                    className: "fixed inset-0 z-[999] grid place-items-center w-full h-full bg-gray-950/75 dark:bg-gray-50/75 backdrop-blur-sm ease-in duration-300",
                    role: "dialog"
                  }, React.createElement("div", {
                        className: "max-w-xl w-full mx-auto p-4 bg-white rounded-lg shadow-lg"
                      }, React.createElement("div", {
                            className: "flex items-center justify-between"
                          }, React.createElement("h2", {
                                className: "font-semibold leading-tight text-gray-900"
                              }, tr(undefined, undefined, "report")), React.createElement("button", {
                                className: "w-6 h-6 flex items-center justify-center rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-focusColor-500 focus:ring-offset-2 transition",
                                onClick: (function (param) {
                                    return updateShowReport(setShowReport, false, param);
                                  })
                              }, React.createElement(Icon.make, {
                                    className: "if i-times-light text-xl if-fw"
                                  }), React.createElement("span", {
                                    className: "sr-only"
                                  }, tr(undefined, undefined, "close")))), React.createElement("textarea", {
                            className: "w-full text-sm text-gray-900 p-2 border rounded-md mt-4",
                            id: "report_reason-" + reportableId,
                            autoFocus: true,
                            placeholder: tr(undefined, undefined, "share_reason"),
                            type: "text",
                            value: reportReason,
                            onChange: (function (param) {
                                return updateReportReason(setReportReason, param);
                              })
                          }), React.createElement("div", {
                            className: "mt-3 sm:mt-4 sm:flex"
                          }, React.createElement("button", {
                                className: "btn btn-primary",
                                disabled: reportReason === "",
                                onClick: (function (param) {
                                    return createModerationReport(reportableType, reportableId, reportReason, setModerationReports, setShowReport, setReportReason, param);
                                  })
                              }, tr(undefined, undefined, "report")), React.createElement("button", {
                                className: "mt-3 inline-flex w-full justify-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500",
                                onClick: (function (param) {
                                    return updateShowReport(setShowReport, false, param);
                                  })
                              }, tr(undefined, undefined, "cancel"))))) : null, reported ? React.createElement("div", {
                    className: "flex items-center justify-center p-1 text-sm text-red-600 whitespace-nowrap"
                  }, React.createElement("span", {
                        className: "flex items-center md:space-x-1"
                      }, React.createElement(Icon.make, {
                            className: "if i-flag-light if-fw"
                          }), React.createElement("span", {
                            className: "hidden md:inline-block text-xs"
                          }, tr(undefined, undefined, "reported")))) : React.createElement("button", {
                    className: "curriculum-moderation__report-button md:hidden md:group-hover:flex md:group-focus-within:flex items-center justify-center cursor-pointer p-1 text-sm border rounded-md text-gray-700 bg-gray-100 hover:text-gray-800 hover:bg-gray-50 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500 transition",
                    disabled: reported,
                    onClick: (function (param) {
                        return updateShowReport(setShowReport, true, param);
                      })
                  }, React.createElement("span", {
                        className: "flex items-center md:space-x-1"
                      }, React.createElement(Icon.make, {
                            className: "if i-flag-light if-fw"
                          }), React.createElement("span", {
                            className: "hidden md:inline-block text-xs"
                          }, tr(undefined, undefined, "report")))));
}

var make$1 = CoursesCurriculum__ModerationReportButton;

export {
  str ,
  tr ,
  CreateModerationReportMutation ,
  createModerationReport ,
  updateReportReason ,
  updateShowReport ,
  make$1 as make,
}
/* include Not a pure module */
