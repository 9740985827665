// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as CoursesCurriculum__User from "../types/CoursesCurriculum__User.bs.js";
import * as CoursesCurriculum__Reactions from "./CoursesCurriculum__Reactions.bs.js";
import * as CoursesCurriculum__CurrentUser from "../types/CoursesCurriculum__CurrentUser.bs.js";
import * as CoursesCurriculum__SubmissionComment from "../types/CoursesCurriculum__SubmissionComment.bs.js";
import * as CoursesCurriculum__ModerationReportButton from "./CoursesCurriculum__ModerationReportButton.bs.js";

import "./CoursesCurriculum__SubmissionCommentShow.css";
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionCommentShow";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation HideSubmissionCommentMutation($submissionCommentId: String!, $hide: Boolean!)  {\nhideSubmissionComment(submissionCommentId: $submissionCommentId, hide: $hide)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.hideSubmissionComment;
  return {
          hideSubmissionComment: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.hideSubmissionComment;
  var value$2 = value$1.success;
  var hideSubmissionComment = {
    success: value$2
  };
  return {
          hideSubmissionComment: hideSubmissionComment
        };
}

function serializeVariables(inp) {
  return {
          submissionCommentId: inp.submissionCommentId,
          hide: inp.hide
        };
}

function makeVariables(submissionCommentId, hide, param) {
  return {
          submissionCommentId: submissionCommentId,
          hide: hide
        };
}

var HideSubmissionCommentMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var HideSubmissionCommentMutation_Graphql_error = include.Graphql_error;

var HideSubmissionCommentMutation_decodeNotification = include.decodeNotification;

var HideSubmissionCommentMutation_decodeObject = include.decodeObject;

var HideSubmissionCommentMutation_decodeNotifications = include.decodeNotifications;

var HideSubmissionCommentMutation_decodeErrors = include.decodeErrors;

var HideSubmissionCommentMutation_flashNotifications = include.flashNotifications;

var HideSubmissionCommentMutation_sendQuery = include.sendQuery;

var HideSubmissionCommentMutation_query = include.query;

var HideSubmissionCommentMutation_fetch = include.$$fetch;

var HideSubmissionCommentMutation = {
  HideSubmissionCommentMutation_inner: HideSubmissionCommentMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: HideSubmissionCommentMutation_Graphql_error,
  decodeNotification: HideSubmissionCommentMutation_decodeNotification,
  decodeObject: HideSubmissionCommentMutation_decodeObject,
  decodeNotifications: HideSubmissionCommentMutation_decodeNotifications,
  decodeErrors: HideSubmissionCommentMutation_decodeErrors,
  flashNotifications: HideSubmissionCommentMutation_flashNotifications,
  sendQuery: HideSubmissionCommentMutation_sendQuery,
  query: HideSubmissionCommentMutation_query,
  $$fetch: HideSubmissionCommentMutation_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "mutation ArchiveSubmissionCommentMutation($submissionCommentId: String!)  {\narchiveSubmissionComment(submissionCommentId: $submissionCommentId)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.archiveSubmissionComment;
  return {
          archiveSubmissionComment: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.archiveSubmissionComment;
  var value$2 = value$1.success;
  var archiveSubmissionComment = {
    success: value$2
  };
  return {
          archiveSubmissionComment: archiveSubmissionComment
        };
}

function serializeVariables$1(inp) {
  return {
          submissionCommentId: inp.submissionCommentId
        };
}

function makeVariables$1(submissionCommentId, param) {
  return {
          submissionCommentId: submissionCommentId
        };
}

var ArchiveSubmissionCommentMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$1.make;

var ArchiveSubmissionCommentMutation_Graphql_error = include$1.Graphql_error;

var ArchiveSubmissionCommentMutation_decodeNotification = include$1.decodeNotification;

var ArchiveSubmissionCommentMutation_decodeObject = include$1.decodeObject;

var ArchiveSubmissionCommentMutation_decodeNotifications = include$1.decodeNotifications;

var ArchiveSubmissionCommentMutation_decodeErrors = include$1.decodeErrors;

var ArchiveSubmissionCommentMutation_flashNotifications = include$1.flashNotifications;

var ArchiveSubmissionCommentMutation_sendQuery = include$1.sendQuery;

var ArchiveSubmissionCommentMutation_query = include$1.query;

var ArchiveSubmissionCommentMutation_fetch = include$1.$$fetch;

var ArchiveSubmissionCommentMutation = {
  ArchiveSubmissionCommentMutation_inner: ArchiveSubmissionCommentMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: ArchiveSubmissionCommentMutation_Graphql_error,
  decodeNotification: ArchiveSubmissionCommentMutation_decodeNotification,
  decodeObject: ArchiveSubmissionCommentMutation_decodeObject,
  decodeNotifications: ArchiveSubmissionCommentMutation_decodeNotifications,
  decodeErrors: ArchiveSubmissionCommentMutation_decodeErrors,
  flashNotifications: ArchiveSubmissionCommentMutation_flashNotifications,
  sendQuery: ArchiveSubmissionCommentMutation_sendQuery,
  query: ArchiveSubmissionCommentMutation_query,
  $$fetch: ArchiveSubmissionCommentMutation_fetch,
  make: make$1
};

function hideComment(submissionCommentId, hide, setCommentHidden, $$event) {
  $$event.preventDefault();
  Js_promise.then_((function (response) {
          if (response.hideSubmissionComment.success) {
            Curry._1(setCommentHidden, (function (param) {
                    return hide;
                  }));
          }
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            submissionCommentId: submissionCommentId,
            hide: hide
          }));
}

function archiveComment(submissionCommentId, setShowConfirmDelete, archiveCommentCB, $$event) {
  $$event.preventDefault();
  Curry._1(setShowConfirmDelete, (function (param) {
          return false;
        }));
  Js_promise.then_((function (response) {
          if (response.archiveSubmissionComment.success) {
            Curry._1(archiveCommentCB, submissionCommentId);
          }
          return Promise.resolve(undefined);
        }), Curry._3(make$1, undefined, undefined, {
            submissionCommentId: submissionCommentId
          }));
}

function updateShowConfirmDelete(setShowConfirmDelete, showConfirmDelete, $$event) {
  $$event.preventDefault();
  return Curry._1(setShowConfirmDelete, (function (param) {
                return showConfirmDelete;
              }));
}

function CoursesCurriculum__SubmissionCommentShow(Props) {
  var currentUser = Props.currentUser;
  var comment = Props.comment;
  var archiveCommentCB = Props.archiveCommentCB;
  var match = React.useState(function () {
        return Belt_Option.isSome(CoursesCurriculum__SubmissionComment.hiddenAt(comment));
      });
  var setCommentHidden = match[1];
  var commentHidden = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowConfirmDelete = match$1[1];
  var isModerator = CoursesCurriculum__CurrentUser.isModerator(currentUser);
  var userName = CoursesCurriculum__User.name(CoursesCurriculum__SubmissionComment.user(comment));
  var tmp;
  if (isModerator) {
    var partial_arg = !commentHidden;
    var partial_arg$1 = CoursesCurriculum__SubmissionComment.id(comment);
    tmp = React.createElement("div", {
          className: "relative z-[11]"
        }, React.createElement("button", {
              className: "md:hidden md:group-hover:flex md:group-focus-within:flex md:space-x-1 items-center justify-center cursor-pointer p-1 text-sm border rounded-md text-gray-700 bg-gray-100 hover:text-gray-800 hover:bg-gray-50 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-600 transition",
              title: commentHidden ? "Unhide Comment" : "Hide Comment",
              onClick: (function (param) {
                  return hideComment(partial_arg$1, partial_arg, setCommentHidden, param);
                })
            }, commentHidden ? React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-eye-closed-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, tr(undefined, undefined, "unhide"))) : React.createElement("span", {
                    className: "flex items-center md:space-x-1"
                  }, React.createElement(Icon.make, {
                        className: "if i-eye-light if-fw"
                      }), React.createElement("span", {
                        className: "hidden md:inline-block text-xs"
                      }, tr(undefined, undefined, "hide")))));
  } else {
    tmp = null;
  }
  var commentDisplay = React.createElement("div", {
        "aria-label": "comment-" + CoursesCurriculum__SubmissionComment.id(comment),
        className: "relative mt-4 group curriculum-submission__comment focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-500",
        tabIndex: 0
      }, React.createElement("div", {
            className: commentHidden ? "relative curriculum__submission-comment-hidden rounded-b-xl" : "flex-1"
          }, React.createElement("div", {
                className: "flex flex-row items-center justify-between"
              }, React.createElement("div", undefined, React.createElement("div", {
                        className: "flex gap-3"
                      }, React.createElement("div", {
                            className: "submission-comments__line flex justify-end align-start absolute h-full -left-8 -ml-[0.5px] bottom-1 w-8"
                          }, React.createElement("div", {
                                className: "h-6 border-b cursor-pointer w-7 border-l border-gray-300 rounded-bl-3xl"
                              })), React.createElement("div", {
                            className: "w-8 h-8 border bg-gray-200 rounded-full flex items-center justify-center"
                          }, CoursesCurriculum__User.avatar(CoursesCurriculum__SubmissionComment.user(comment))), React.createElement("div", {
                            className: "flex flex-col flex-wrap"
                          }, React.createElement("p", {
                                className: "font-semibold text-xs leading-tight block md:inline-flex"
                              }, userName), React.createElement("p", {
                                className: "text-xs text-gray-600 leading-tight pt-1",
                                title: CoursesCurriculum__SubmissionComment.createdAtPretty(comment)
                              }, CoursesCurriculum__SubmissionComment.createdAtPretty(comment))))), React.createElement("div", {
                    className: "flex space-x-2 relative"
                  }, tmp, CoursesCurriculum__CurrentUser.id(currentUser) === CoursesCurriculum__SubmissionComment.userId(comment) ? React.createElement("div", {
                          className: "md:hidden md:group-hover:flex relative z-[11]"
                        }, React.createElement("button", {
                              className: "md:hidden md:group-hover:flex md:group-focus-within:flex md:space-x-1 items-center justify-center cursor-pointer p-1 text-sm border rounded-md text-gray-700 bg-gray-100 hover:text-gray-800 hover:bg-gray-50 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-focusColor-600 transition",
                              onClick: (function (param) {
                                  return updateShowConfirmDelete(setShowConfirmDelete, true, param);
                                })
                            }, React.createElement(Icon.make, {
                                  className: "if i-trash-light if-fw"
                                }), React.createElement("span", {
                                  className: "hidden md:inline-block text-xs"
                                }, tr(undefined, undefined, "delete")))) : React.createElement("div", {
                          className: "relative z-[13]"
                        }, React.createElement(CoursesCurriculum__ModerationReportButton.make, {
                              currentUser: currentUser,
                              moderationReports: CoursesCurriculum__SubmissionComment.moderationReports(comment),
                              reportableId: CoursesCurriculum__SubmissionComment.id(comment),
                              reportableType: "SubmissionComment"
                            })))), React.createElement(MarkdownBlock.make, {
                markdown: CoursesCurriculum__SubmissionComment.comment(comment),
                className: "text-sm ms-11 mt-2",
                profile: /* Permissive */0
              }), React.createElement("div", {
                className: "ms-11"
              }, React.createElement(CoursesCurriculum__Reactions.make, {
                    currentUser: currentUser,
                    reactionableType: "SubmissionComment",
                    reactionableId: CoursesCurriculum__SubmissionComment.id(comment),
                    reactions: CoursesCurriculum__SubmissionComment.reactions(comment)
                  }))), commentHidden ? React.createElement("div", {
              className: "absolute -translate-x-1/2 left-1/2 z-[10] flex justify-end mx-auto bottom-px"
            }, React.createElement("p", {
                  className: "px-2 py-1 bg-white/20 border border-gray-300 border-b-0 whitespace-nowrap rounded-t-lg text-xs leading-tight italic text-gray-500"
                }, tr(undefined, undefined, "hidden"))) : null);
  var tmp$1;
  if (match$1[0]) {
    var partial_arg$2 = CoursesCurriculum__SubmissionComment.id(comment);
    tmp$1 = React.createElement("dialog", {
          className: "fixed inset-0 z-[999] grid place-items-center w-full h-full bg-gray-950/75 dark:bg-gray-50/75 backdrop-blur-sm ease-in duration-300",
          role: "dialog"
        }, React.createElement("div", {
              className: "max-w-xl mx-auto relative p-4 bg-white rounded-lg shadow-lg"
            }, React.createElement("div", {
                  className: "sm:flex sm:items-start"
                }, React.createElement("div", {
                      className: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    }, React.createElement("svg", {
                          "aria-hidden": true,
                          className: "h-6 w-6 text-red-600",
                          fill: "none",
                          stroke: "currentColor",
                          strokeWidth: "1.5",
                          viewBox: "0 0 24 24"
                        }, React.createElement("path", {
                              d: "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z",
                              strokeLinecap: "round",
                              strokeLinejoin: "round"
                            }))), React.createElement("div", {
                      className: "mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left"
                    }, React.createElement("h3", {
                          className: "text-base font-semibold leading-6 text-gray-900",
                          id: "modal-title"
                        }, tr(undefined, undefined, "delete_comment")), React.createElement("div", {
                          className: "mt-2"
                        }, React.createElement("p", {
                              className: "text-sm text-gray-500"
                            }, tr(undefined, undefined, "delete_confirm"))), React.createElement("div", {
                          className: "absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
                        }, React.createElement("button", {
                              className: "w-6 h-6 flex items-center justify-center rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition",
                              autoFocus: true,
                              onClick: (function (param) {
                                  return updateShowConfirmDelete(setShowConfirmDelete, false, param);
                                })
                            }, React.createElement(Icon.make, {
                                  className: "if i-times-light text-xl if-fw"
                                }), React.createElement("span", {
                                  className: "sr-only"
                                }, tr(undefined, undefined, "close")))))), React.createElement("div", {
                  className: "mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4"
                }, React.createElement("button", {
                      className: "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto",
                      onClick: (function (param) {
                          return archiveComment(partial_arg$2, setShowConfirmDelete, archiveCommentCB, param);
                        })
                    }, tr(undefined, undefined, "delete")), React.createElement("button", {
                      className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto",
                      onClick: (function (param) {
                          return updateShowConfirmDelete(setShowConfirmDelete, false, param);
                        })
                    }, tr(undefined, undefined, "cancel")))));
  } else {
    tmp$1 = null;
  }
  return React.createElement("div", {
              id: "comment-" + CoursesCurriculum__SubmissionComment.id(comment)
            }, tmp$1, commentDisplay);
}

var make$2 = CoursesCurriculum__SubmissionCommentShow;

export {
  str ,
  tr ,
  HideSubmissionCommentMutation ,
  ArchiveSubmissionCommentMutation ,
  hideComment ,
  archiveComment ,
  updateShowConfirmDelete ,
  make$2 as make,
}
/*  Not a pure module */
