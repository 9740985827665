// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as ContentBlock from "../../../shared/types/ContentBlock.bs.js";
import * as TargetContentView from "../../../shared/components/TargetContentView.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";

import "./CoursesCurriculum__Learn.css"
;

var partial_arg = "components.CoursesCurriculum__Learn";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function CoursesCurriculum__Learn(Props) {
  var targetDetails = Props.targetDetails;
  var author = Props.author;
  var courseId = Props.courseId;
  var targetId = Props.targetId;
  return React.createElement("div", {
              id: "learn-component"
            }, ReactUtils.nullUnless(React.createElement("a", {
                      className: "btn btn-primary-ghost btn-small course-curriculum__learn-edit-content-btn",
                      href: "/school/courses/" + courseId + "/targets/" + targetId + "/content"
                    }, React.createElement("i", {
                          className: "fas fa-pencil-alt"
                        }), React.createElement("span", {
                          className: "ms-2"
                        }, t(undefined, undefined, "edit_target_button"))), author), React.createElement(TargetContentView.make, {
                  contentBlocks: ContentBlock.sort(CoursesCurriculum__TargetDetails.contentBlocks(targetDetails))
                }));
}

var make = CoursesCurriculum__Learn;

export {
  t ,
  make ,
}
/*  Not a pure module */
