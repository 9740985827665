// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function userId(t) {
  return t.userId;
}

function userName(t) {
  return t.userName;
}

function reactionableId(t) {
  return t.reactionableId;
}

function reactionableType(t) {
  return t.reactionableType;
}

function reactionValue(t) {
  return t.reactionValue;
}

function updatedAt(t) {
  return t.updatedAt;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          userId: Json_decode.field("userId", Json_decode.string, json),
          userName: Json_decode.field("userName", Json_decode.string, json),
          reactionableId: Json_decode.field("reactionableId", Json_decode.string, json),
          reactionableType: Json_decode.field("reactionableType", Json_decode.string, json),
          reactionValue: Json_decode.field("reactionValue", Json_decode.string, json),
          updatedAt: Json_decode.field("updatedAt", DateFns.decodeISO, json)
        };
}

export {
  id ,
  userId ,
  userName ,
  reactionableId ,
  reactionableType ,
  reactionValue ,
  updatedAt ,
  decode ,
}
/* DateFns Not a pure module */
