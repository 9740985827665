// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Dropdown from "../../../shared/components/Dropdown.bs.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as CoursesCurriculum__Level from "../types/CoursesCurriculum__Level.bs.js";

function str(prim) {
  return prim;
}

function levelZeroSelectorClasses(isSelected) {
  return "w-1/2 px-4 py-2 focus:outline-none text-sm font-semibold flex items-center justify-center " + (
          isSelected ? "bg-primary-100 text-primary-500 hover:bg-primary-100 hover:text-primary-500" : ""
        );
}

function levelName(level) {
  return LevelLabel.format(true, CoursesCurriculum__Level.name(level), String(CoursesCurriculum__Level.number(level)));
}

function selectableLevels(orderedLevels, setSelectedLevelId, preview) {
  return Js_array.map((function (level) {
                var icon = preview ? "fas fa-eye" : (
                    CoursesCurriculum__Level.isUnlocked(level) ? "inline-block" : "fas fa-lock text-gray-600"
                  );
                return React.createElement("button", {
                            key: CoursesCurriculum__Level.id(level),
                            className: "flex focus:outline-none p-2 w-full whitespace-normal",
                            onClick: (function (param) {
                                Curry._1(setSelectedLevelId, CoursesCurriculum__Level.id(level));
                              })
                          }, React.createElement("span", {
                                className: "me-2 mt-px"
                              }, React.createElement(FaIcon.make, {
                                    classes: "fa-fw " + icon
                                  })), levelName(level));
              }), orderedLevels);
}

function untabbedLevelSelector(selectedLevel, orderedLevels, setSelectedLevelId, preview) {
  var selected = React.createElement("button", {
        className: "font-semibold w-full px-2 h-10 flex items-center justify-between"
      }, React.createElement("span", {
            className: "grow truncate w-0"
          }, levelName(selectedLevel)), React.createElement(FaIcon.make, {
            classes: "fas fa-caret-down ms-1"
          }));
  return React.createElement(Dropdown.make, {
              selected: selected,
              contents: selectableLevels(orderedLevels, setSelectedLevelId, preview),
              className: "grow cursor-pointer rounded-lg bg-primary-100 hover:bg-gray-50 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-inset focus-witin:ring-focusColor-500 focus:text-primary-500 focus:bg-gray-50"
            });
}

function tabbedLevelSelector(orderedLevels, selectedLevel, setSelectedLevelId, showLevelZero, setShowLevelZero, levelZero, preview) {
  var selected = function (hideCaret) {
    return React.createElement("button", {
                className: "rounded-s-lg font-semibold w-full px-2 h-10 flex items-center justify-between"
              }, React.createElement("span", {
                    className: "grow text-center truncate w-0"
                  }, levelName(selectedLevel)), React.createElement(FaIcon.make, {
                    classes: "fas fa-caret-down ms-1" + (
                      hideCaret ? " invisible" : ""
                    )
                  }));
  };
  var numberedLevelSelector = showLevelZero ? React.createElement("div", {
          className: "cursor-pointer text-sm grow rounded-s-lg hover:bg-gray-50 hover:text-primary-500",
          onClick: (function (param) {
              Curry._1(setShowLevelZero, false);
            })
        }, selected(true)) : React.createElement(Dropdown.make, {
          selected: selected(false),
          contents: selectableLevels(orderedLevels, setSelectedLevelId, preview),
          className: "cursor-pointer grow rounded-s-lg bg-primary-100 hover:bg-gray-50 hover:text-primary-500",
          key: "numbered-level-selector"
        });
  return [
          numberedLevelSelector,
          React.createElement("button", {
                key: "level-zero-selector",
                className: "border-s rounded-e-lg bg-white border-gray-300 font-semibold truncate hover:bg-gray-50 hover:text-primary-500 " + levelZeroSelectorClasses(showLevelZero),
                onClick: (function (_e) {
                    Curry._1(setShowLevelZero, true);
                  })
              }, CoursesCurriculum__Level.name(levelZero))
        ];
}

function CoursesCurriculum__LevelSelector(Props) {
  var levels = Props.levels;
  var selectedLevel = Props.selectedLevel;
  var preview = Props.preview;
  var setSelectedLevelId = Props.setSelectedLevelId;
  var showLevelZero = Props.showLevelZero;
  var setShowLevelZero = Props.setShowLevelZero;
  var levelZero = Props.levelZero;
  var orderedLevels = CoursesCurriculum__Level.sort(Js_array.filter((function (l) {
              return CoursesCurriculum__Level.number(l) !== 0;
            }), levels));
  return React.createElement("div", {
              className: "bg-gray-50 px-3 py-2 mt-3 md:px-0 sticky top-0 z-10"
            }, React.createElement("div", {
                  className: "flex justify-center max-w-sm md:max-w-xl mx-auto rounded-lg border border-gray-300 h-11"
                }, levelZero !== undefined ? tabbedLevelSelector(orderedLevels, selectedLevel, setSelectedLevelId, showLevelZero, setShowLevelZero, levelZero, preview) : untabbedLevelSelector(selectedLevel, orderedLevels, setSelectedLevelId, preview)));
}

var make = CoursesCurriculum__LevelSelector;

export {
  str ,
  levelZeroSelectorClasses ,
  levelName ,
  selectableLevels ,
  untabbedLevelSelector ,
  tabbedLevelSelector ,
  make ,
}
/* react Not a pure module */
